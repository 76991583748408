.catbutton{
    /* color:white;
    background: #b4bdc3;
    color: white;
    margin: 5px;
    padding: 6px;
    box-shadow: inset 3px 6px 5px 0px; */
    cursor: pointer;
}

.catbutton:active{
    box-shadow: none;
}

.catactive{
    background: rgb(61, 122, 129);
    color: white ;
    /* margin: 5px; */
    padding: 0px 7px;
    cursor: pointer;
    border-radius: 8px;
}

    .mcat{
        display: none;
    }

    .dcat{
      display: block;
    }


    @media screen and  (max-width: 576px) {
       .mcat{
        display: block;
       }
       .dcat{
        display: none;
       }

       .product__item__pic {
        height: 150px !important;
        position: relative;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
    }
    }

    .sidebar{
        text-align: center;
        padding: 1px 8px;
        border-right: 1px solid lightgray;
        height: 80vh;
        overflow-y: auto;
    }
