.cartButton {
  content: attr(value);
  font-size: 12px;
  background: red;
  color: #fff;
  border-radius: 50px;
  padding: 0 5px;
  position: relative;
  left: -8px;
  top: -10px;
  opacity: 0.9;
}

/* Styling for the button */
.toggle-btn {
  padding: 5px 5px;
  font-size: 12px;
  border: 3px solid #6e6c6c;
  border-radius: 20px;
  background-color: #fff6f6;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* When the toggle is ON */
.toggle-btn.on {
  background-color: #7fad39;
  /* Green for ON */
  color: white;
}

/* When the toggle is OFF */
.toggle-btn.off {
  background-color: rgb(232, 48, 48);
  /* Grey for OFF */
  color: #333;
}

p {
  font-size: 18px;
  font-weight: bold;
}

.vendor__logo {
  border-radius: 5px;
  height: 65px;
  margin-right: 10px;
}

.vendor__logo_blank {
  padding: 24px 0;
}

@media (max-width: 768px) {
  .vendor_detail {
    /* width: 220px; 
       height: 220px; */
    padding-top: 18px !important;
  }
}

.vendor_name {
  font-size: 20px;
  font-weight: 700;
}

.vendor_detail {
  padding-top: 65px;
}

.header-fix {
  position: sticky;
  top: 0;
  background: white;
  z-index: 99;
  box-shadow:0px 1px 1px 0px lightgray;
}
