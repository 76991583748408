.cartList{
    color: red;
    font-size: 12px; 
    font-variant-numeric: proportional-nums; 
}
.total{
    color: red;
    text-align: right;

}
.discount{
    color: green;
    text-align: right;
    text-align: left;
}
.hyper{
    border-top-style: outset;
}

@media (max-width: 480px) {
    .shoping__cart__table table tr th,td{
        font-size: 14px !important;
    }
    .shoping__cart__table table img{
        height: 10vh;
    }
  }
