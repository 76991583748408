.shop-visits {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.shop-visits h2,
.shop-visits h3 {
  margin-bottom: 20px;
}

.recent-shops,
.more-shops {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 40px;
}

.shop-card {
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.shop-card h3 {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: bold;
}

.shop-card p {
  font-size: 14px;
  color: #555;
}

.shop-card-container {
  display: flex;
  justify-content: center;
}

.shop-card img{
  height: 25vh;
  width: 20vw;
}

@media (max-width: 768px) {

  .recent-shops,
  .more-shops {
    grid-template-columns: repeat(2, 1fr);
  }
  .shop-card img{
    height: 10vh;
    width: 30vw;
  }
}

@media (max-width: 480px) {

  .recent-shops,
  .more-shops {
    grid-template-columns: repeat(2, 1fr);
  }
  .shop-card img{
    height: 10vh;
    width: 30vw;
  }
  .shop-visits h2{
    font-size: 22px;
  }
}